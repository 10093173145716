@import '../../global.scss';

.works{
    background-color: #14213d;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
   
    .arrow{
         height: 50px;
         position: absolute;
         background-color: #14213d;

         @include mobile{
            display: none;
        }
         
         &.Left{
             left: 150px;
             transform: rotate(180deg);
         }

         &.Right{
             right: 150px;
         }
    }

    .slider{
        height: 350px;
        display: flex;
        position: absolute;
        left: 0vw;
        transition: all 1s ease-out;

        @include mobile{
            height: 100vh;
            flex-direction: column;
            justify-content: center;
        }

        .container{
        
            width: 100vw;
            display: flex;
            align-items: center;
            justify-content: center;
            
            .item{
                width: 700px;
                height: 100%;
                background-color: white;
                border-radius: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                @include mobile{
                    margin: 15px 0;
                    width: 80%;
                    height: 150px;
                }
                
                .left{
                    flex: 4;
                    height: 80%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .leftContainer{
                        width: 90%;
                        height: 70%;
                        display: flex;
                        justify-content: space-between;
                        flex-direction: column;

                  
                        .imgContainer{
                            width: 40px;
                            height: 40px;
                            border-radius: 50%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background-color: #fca311;

                            @include mobile{
                                width: 20px;
                                height: 20px;
                            }

                            img{
                                width: 25px;

                                @include mobile{
                                    width: 15px;
                                    height: 20px;
                                }
    
                            }

                        }
                      h2{
                          font-size: 20px;
                          @include mobile{
                            font-size: 13px;
                        }

                      }
                      p{
                          font-size: 13px;
                          @include mobile{
                            display: none;
                        }

                      }
                      span{
                          font-size: 12px;
                          font-weight: 600;
                          text-decoration: underline;
                          cursor: pointer;
                      }
                    }

                }
                .right{
                    flex: 8;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    overflow: hidden;
                
                    img{
                        width: 400px;
                        height: 100%;
                        transform: rotate(-10deg);
                       

                    }
                }
                
            }
        }
    }
}

