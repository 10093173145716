@import "../../global.scss";

.menu{
    width: 300px;
    height:100vh;
    background-color: $mainColor;
    position: fixed;
    top: 0px;
    right: -300px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all 1s ease;
   

    &.active{
        right:0;
    
    }
  
    ul{
        margin: 0;
        padding: 0;
        top: 90px;
        position: relative;
        list-style: none;
        font-size: 50px;
        font-weight: 300;
        color:white;
        width: 65%;

        li{
            margin-bottom: 25px;
            a{
                font-size: inherit;
                color: inherit;
                text-decoration: none;

                #intro{
                    margin-top: 25px;
                }
            }
            &:hover{
                font-weight: 500;
            }
        }
    }
}