@import "../../global.scss";

.portfolio {
    background-color: white;
    display: flex;
    align-items: center;
    flex-direction: column;

    h1{
        font-size: 50px;

        @include mobile{
            font-size: 20px;
        }

    }

    ul{
        margin: 10px;
        padding: 0;
        list-style: none;
        display: flex;

        @include mobile{
            margin: 10px 0;
            flex-wrap: wrap;
            justify-content: center;
        }
        
     
    }

    .container{
        width: 70%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        @include mobile{
            width: 100%;
        }

        .item{
            width:220px;
            height: 150px;
            margin: 2px;
            border-radius: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            border: 1px solid #fca311;
            transition: all 0.5s ease;
            cursor: pointer;

            h3{
                color: white;
                position: absolute;
                font-size: 20px;
            }
    
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                z-index: 1;
            }
            
            &:hover{
                background-color: $mainColor;
                img{
                    opacity: 0.2;
                    z-index: 0;
                }
            }
        }
        
        
 
    }
}
