@import "../../global.scss";

.contact{
    background-color: white;
    margin-top: 10px;
 

    @include mobile{
        flex-direction: column;
    }

    // .left{
    //     flex: 1;
    //     overflow: hidden;

    //     img{
    //         height: 100%;
    //     }
    // }
    .right{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        .container{
            display: flex;
            justify-content: center;
            align-items: center;
            a{
                margin-right: 10px;
                margin-top: 20px;
                cursor: pointer;
                color: black;
            }
        }

        a{
            margin-top: 20px;
            cursor: pointer;
            color: black;
        }

        //  form{
        //      width: 70%;
        //      height: 70%;
        //      display: flex;
        //      flex-direction: column;
        //      align-items: center;
        //      justify-content: space-around;

        //      input{
        //          width: 300px;
        //          height: 30px;
        //          font-size: 14px;

        //          @include mobile{
        //             width: 200px;
        //             height: 20px;
        //         }
        //      }
        //      textarea{
        //          width: 300px;
        //          height: 200px;
        //          font-size: 14px;

        //          @include mobile{
        //             width: 200px;
        //             height: 100px;
        //         }
        //      }

        //      button{
        //          width: 150px;
        //          height: 30px;
        //          color: white;
        //          border-radius: 10px;
        //          background-color: crimson;
        //          border: none;
        //          cursor: pointer;
        //      }

        //      &:focus{
        //          outline: none;
        //      }
        //  }

        //  span{
        //      color:aquamarine
        //  }

    }
}